import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// const Foo = { template: '<embed src="https://qccdata.qichacha.com/ReportData/PDF/da960b414863fe970245e716b33c5339.pdf#view=fitH&amp;toolbar=0&amp;statusbar=0&amp;navpanes=0&amp;messages=0" type="application/pdf" class="pdf-module" data-v-55a5d2fa>' }

const routes = [
  { path: '/', component: () => import('./home') }
  // { path: '/test', component: Foo }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

