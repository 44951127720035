import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import router from './routers'
// import './get-user.js'

Vue.config.productionTip = false
if (__DEV__ || __SIT__) {
  const VConsole = require('vconsole')
  const vConsole = new VConsole()
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
Vue.use(Vant)





