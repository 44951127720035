<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>


<script>

export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.copy-input {
    position: absolute;
    top: -50;
    left: 0;
    opacity: 0;
    z-index: -10;
    width: 0px;
    height: 0px;
}
.van-dialog__confirm, .van-dialog__confirm:active {
    color: #128bed !important;
}
</style>
